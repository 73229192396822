<template>
  <div>
    <div class="flex-row">
      <div v-html="aspect.text" class="rich-text-content"></div>
    </div>
    <div class="mb-1">
      <b-form-group
        label="Acuerdos"
        label-cols="0"
        label-cols-sm="2"
        label-cols-lg="2"
        label-cols-xl="2"
        :label-for="`textarea-agreement-${agreement.id}`"
        label-class="middle"
        class="m-0 mb-3"
      >
        <b-form-textarea
          :id="`textarea-agreement-${agreement.id}`"
          v-model="agreement.agreement"
          :state="validateState('agreement')"
          rows="1"
          max-rows="4"
          class="m-0"
          @input="saved = false"
        ></b-form-textarea>
        <b-form-invalid-feedback :id="`textarea-agreement-${agreement.id}`"
          >Este campo es obligatorio.</b-form-invalid-feedback
        >
      </b-form-group>
      <b-form-group
        label="Responsables"
        label-cols="0"
        label-cols-sm="2"
        label-cols-lg="2"
        label-cols-xl="2"
        :label-for="`textarea-responsibles-${agreement.id}`"
        label-class="middle"
        class="m-0 mb-3"
      >
        <b-form-textarea
          :id="`textarea-responsibles-${agreement.id}`"
          v-model="agreement.responsibles"
          :state="validateState('responsibles')"
          rows="1"
          max-rows="4"
          class="m-0"
          @input="saved = false"
        ></b-form-textarea>
        <b-form-invalid-feedback :id="`textarea-responsibles-${agreement.id}`"
          >Este campo es obligatorio.</b-form-invalid-feedback
        >
      </b-form-group>
      <b-form-group
        label="Fecha de Cumplimiento"
        label-cols="0"
        label-cols-sm="2"
        label-cols-lg="2"
        label-cols-xl="2"
        :label-for="`input-date-${agreement.id}`"
        label-class="middle"
        class="m-0 mb-2"
      >
        <b-form-datepicker
          :id="`input-date-${agreement.id}`"
          v-model="agreement.compliance_date"
          :state="validateState('compliance_date')"
          @input="saved = false"
        ></b-form-datepicker>
        <b-form-invalid-feedback :id="`input-date-${agreement.id}`"
          >Este campo es obligatorio.</b-form-invalid-feedback
        >
      </b-form-group>
      <b-form-group
        v-if="!isNaN(agreement.id)"
        label="Cumplimiento"
        label-cols="0"
        label-cols-sm="2"
        label-cols-lg="2"
        label-cols-xl="2"
        :label-for="`radio-fulfilled-${agreement.id}`"
        label-class="middle"
        class="m-0 mb-3"
      >
        <b-form-radio-group
          :id="`radio-fulfilled-${agreement.id}`"
          v-model="agreement.is_fulfilled"
          :state="validateState('is_fulfilled')"
          :options="FulfillTypes"
          value-field="id"
          text-field="value"
          @change="saved = false"
        ></b-form-radio-group>
      </b-form-group>
      <b-form-group
        label="Observaciones"
        label-cols="0"
        label-cols-sm="2"
        label-cols-lg="2"
        label-cols-xl="2"
        :label-for="`textarea-observations-${agreement.id}`"
        label-class="middle"
        class="m-0 mb-2"
      >
        <b-form-textarea
          :id="`textarea-observations-${agreement.id}`"
          v-model="agreement.observations"
          :state="validateState('observations')"
          rows="1"
          max-rows="4"
          class="m-0"
          @input="saved = false"
        ></b-form-textarea>
        <b-form-invalid-feedback :id="`textarea-observations-${agreement.id}`"
          >Este campo es obligatorio.</b-form-invalid-feedback
        >
      </b-form-group>
      <div class="row">
        <div
          v-if="show_delete_button && !isNaN(agreement.id)"
          class="col"
          style="text-align: left"
        >
          <b-button class="mr-1" size="sm" variant="danger">Eliminar</b-button>
        </div>
        <div class="col" style="text-align: right">
          <b-button class="mr-1" size="sm" @click="save">Guardar</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { generateUniqueId, toast } from "@/utils/utils";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  name: "AspectAgreementForm",
  mixins: [validationMixin],
  props: {
    Agreement: {
      type: Object,
    },
    act_id: {
      type: Number,
      required: true,
    },
    aspect_id: {
      type: Number,
      required: true,
    },
    show_delete_button: {
      type: Boolean,
      default: false,
    },
    Aspects: {
      type: Array,
      required: true,
    },
    FulfillTypes: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      agreement: {
        id: this.Agreement ? this.Agreement.id : generateUniqueId(),
        aspect: this.Agreement ? this.Agreement.aspect : this.aspect_id,
        agreement: this.Agreement ? this.Agreement.agreement : "",
        responsibles: this.Agreement ? this.Agreement.responsibles : "",
        responsible_users: this.Agreement
          ? this.Agreement.responsible_users
          : [],
        observations: this.Agreement ? this.Agreement.observations : "",
        compliance_date: this.Agreement ? this.Agreement.compliance_date : null,
        is_fulfilled: this.Agreement ? this.Agreement.is_fulfilled : null,
        sections: this.Agreement ? this.Agreement.sections : [],
      },
      saved: this.Agreement ? true : false,
    };
  },
  validations() {
    return {
      agreement: {
        agreement: {
          required,
        },
        responsibles: {
          required,
        },
        compliance_date: {
          required,
        },
        observations: {},
        is_fulfilled: {},
      },
    };
  },
  computed: {
    aspect() {
      return this.Aspects.find((x) => x.id == this.agreement.aspect);
    },
    fulfillment() {
      if (!this.agreement.is_fulfilled) return "";
      const type = this.FulfillTypes.find(
        (x) => x.id == this.agreement.is_fulfilled
      );
      return type.value;
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.agreement[name];
      return $dirty ? !$error : null;
    },
    save() {
      this.$v.agreement.$touch();
      if (this.$v.agreement.$anyError) return;
      if (isNaN(this.agreement.id)) this.createAgreement();
      else this.updateAgreement();
    },
    createAgreement() {
      this.$restful
        .Post(`/cideu/agreement/`, this.agreement)
        .then((response) => {
          this.$emit("created", response);
          this.agreement = response;
          this.saved = true;
          toast("Acuerdo creado.");
        });
    },
    updateAgreement() {
      this.$restful
        .Put(`/cideu/agreement/${this.agreement.id}/`, this.agreement)
        .then((response) => {
          this.$emit("updated", response);
          this.agreement = response;
          this.saved = true;
          toast("Acuerdo actualizado.");
        });
    },
  },
};
</script>

<style scoped src="@/utils/rich_text_editor.css">
</style>

<style scoped>
.form-control.is-valid {
  background-size: calc(0.75em + 1.375rem) calc(0.75em + 0.375rem);
}
.form-control.is-invalid {
  background-size: calc(0.75em + 1.375rem) calc(0.75em + 0.375rem);
}
.flex-row {
  display: flex;
  flex-direction: row;
}
.item-text {
  text-align: justify;
  padding: 0.1em 1em;
  width: 95%;
}
.row-first {
  width: 20%;
  font-weight: bold;
}
.row-second {
  width: 80%;
  text-align: justify;
}
.agreement {
  border: 1px dashed var(--secondary-color);
}
.middle {
  vertical-align: middle;
  margin: 0px;
  padding: 0px;
}
</style>